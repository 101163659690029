/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  title: "Hey there.",
  title2: "Molindu",
  logo_name: "Molindu",
  nickname: "hrishi1999 / picleric",
  full_name: "Molindu Achintha",
  subTitle:
    "My passion for AI, particularly in Machine Learning and Deep Learning, is not only evident through my academic pursuits but also through my active involvement in impactful projects. I am deeply committed to mastering these technologies and exploring their potential to solve complex real-world problems, driven by an unwavering enthusiasm for innovation and learning within the field.",
  resumeLink:
    "https://drive.google.com/file/d/1C8hgC1O_M1GJPhImORnZTut7CnYT-6lX/view?usp=sharing",
  mail: "mailto:hrishipatel99@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/sandaruwanWGMA",
  linkedin: "https://www.linkedin.com/in/molindu-achintha/",
  gmail: "sandaruwanwgma@gmail.com",
  gitlab: " ",
  facebook: " ",
  twitter: "https://twitter.com/sandaruwanWGMA",
  whatsapp: "https://wa.me/+94783030932",
};

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        " Building responsive website front end using ReactJS and NextJS",
        " Developing mobile applications using Flutter, ReactNative and native IOS",
        " Backend development using NodeJS, ExpressJS and Django",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "simple-icons:php",
          style: {
            color: "#7377AD",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#f89820",
          },
        },
        {
          skillName: "Swift",
          fontAwesomeClassname: "simple-icons:swift",
          style: {
            color: "#FF8B28", // Bright Orange
          },
        },
        {
          skillName: "Dart",
          fontAwesomeClassname: "simple-icons:dart",
          style: {
            color: "#29B0EE",
          },
        },
        {
          skillName: "Visual Basic",
          fontAwesomeClassname: "simple-icons:dot-net",
          style: {
            color: "#029FCE",
          },
        },
        {
          skillName: "C",
          fontAwesomeClassname: "simple-icons:c",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NextJS",
          fontAwesomeClassname: "simple-icons:nextdotjs",
          style: {
            color: "#FFFFFF",
            backgroundColor: "#1D1D1D",
            borderRadius: "50%",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
      ],
    },

    {
      title: "Data Science & AI",
      fileName: "DataScienceImg",
      skills: [
        " Experience of working with Computer Vision and Gen AI projects",
        " Certifications by deeplearning.ai and Stanford Online",
        " Experience with 5+ Projects",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "logos-pytorch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Pandas",
          fontAwesomeClassname: "simple-icons:pandas",
          style: {
            backgroundColor: "transparent",
            color: "#6E6E6E",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "University of Moratuwa",
      subtitle: "Bsc in Computer science and enigineering",
      logo_path: "University_of_Moratuwa_logo.png",
      alt_name: "DAL",
      duration: "2022 - 2026",
      descriptions: [],
      website_link: "https://www.uom.lk",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Deep Learning Specialization",
      subtitle: "deeplearning.ai",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link: "",
      alt_name: "deeplearning.ai",
      color_code: "#47A048",
    },
    {
      title: "Sequence Models",
      subtitle: "deeplearning.ai",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link: "",
      alt_name: "deeplearning.ai",
      color_code: "#F6B808",
    },
    {
      title: "Convolutional Neural Networks",
      subtitle: "deeplearning.ai",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link: "",
      alt_name: "deeplearning.ai",
      color_code: "#2AAFED",
    },
    {
      title: "Structuring Machine Learning Projects",
      subtitle: "deeplearning.ai",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link: "",
      alt_name: "deeplearning.ai",
      color_code: "#E2405F",
    },
    {
      title: "Neural Networks and Deep Learning",
      subtitle: "deeplearning.ai",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link: "",
      alt_name: "Google",
      color_code: "#7A7A7A",
    },
    {
      title: "Improving Deep Neural Networks",
      subtitle: "deeplearning.ai",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link: "",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I've completed two internships. I've mostly done projects on my own and I am actively looking for internships. I love organizing workshops to share my knowledge with others.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        // {
        //   title: "IT Developer",
        //   company: "Canada Revenue Agency",
        //   company_url: "https://www.canada.ca/en/revenue-agency.html",
        //   logo_path: "cra.png",
        //   duration: "May 2023 - Ongoing",
        //   location: "Halifax, Nova Scotia",
        //   description:
        //     "Canada Revenue Agency (CRA) administers tax laws for the Government of Canada and for most provinces and territories, and administers various social and economic benefit and incentive programs delivered through the tax system.",
        //   color: "#f10000",
        // },
        // {
        //   title: "Full Stack Developer",
        //   company: "Quicko",
        //   company_url: "https://quicko.com/",
        //   logo_path: "quicko.jpg",
        //   duration: "June 2021 - October 2021",
        //   location: "Ahmedabad",
        //   description:
        //     "Quicko is an online tax planning, preparation & filing platform for individuals & businesses.",
        //   color: "#2962FF",
        // },
      ],
    },
    {
      title: "Internships",
      experiences: [
        // {
        //   title: "Google Summer of Code",
        //   company: "Sugar Labs",
        //   company_url: "https://sugarlabs.org/",
        //   logo_path: "google_logo.png",
        //   duration: "May 2019 - Aug 2019",
        //   location: "Work From Home",
        //   description:
        //     "I worked on the Dashboard project which helps users track their activities while using Sugar OS. I also worked on making a Tamagotchi-like widget for Sugar's Home Screen",
        //   color: "#ee3c26",
        // },
        // {
        //   title: "Android App Developer Intern",
        //   company: "IAS4Sure",
        //   company_url: " ",
        //   logo_path: "buld.jpg",
        //   duration: "December 2019 - February 2020",
        //   location: "Work From Home",
        //   description:
        //     "Internship task was to make a native Android application. ",
        //   color: "#0071C5",
        // },
        // {
        //   title: "Web Developer",
        //   company: "Wrighter Writing Solutions",
        //   company_url: " ",
        //   logo_path: "wrighter.jpg",
        //   duration: "August 2020",
        //   location: "",
        //   description: "Develop a website using PHP and jQuery.",
        //   color: "#56A4D3",
        // },
        // {
        //   title: "Front-End Developer",
        //   company: "VJ TechServe",
        //   company_url: " ",
        //   logo_path: "vjt.png",
        //   duration: "September 2020",
        //   location: "",
        //   description: "Develop a portfolio website using ReactJS",
        //   color: "#800000",
        // },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title:
            "Worked as a company coordinator in CSE career fair 24 at university of Moratuwa",
          company: "Zero Beta",
          company_url: "",
          logo_path: "University_of_Moratuwa_logo.png",
          duration: "",
          location: " ",
          description:
            "My job was to navigate and manage the senior students' interviews at the scheduled time",
          color: "#4285F4",
        },
        // {
        //   title: "Google Code-In Mentor",
        //   company: "Sugar Labs / GSoC",
        //   company_url: "https://sugarlabs.org/",
        //   logo_path: "google_logo.png",
        //   duration: "Winter of 2017 / 18 / 19",
        //   location: " ",
        //   description:
        //     "Day to day resposibilites of this mentorship was to help out children aged 13-17 get started with programming and open-source, review their work and approve them.",
        //   color: "#D83B01",
        // },
        // {
        //   title: "Board Member at Codeuino",
        //   company: " ",
        //   company_url: " ",
        //   logo_path: "codeuino.jpg",
        //   duration: "2018 - 2019",
        //   location: " ",
        //   description: "Previous Board during early days of Codeuino",
        //   color: "#D83B01",
        // },
        // {
        //   title: "BluWings Club",
        //   company: " ",
        //   company_url: " ",
        //   logo_path: "b.png",
        //   duration: "2018 - On Going",
        //   location: " ",
        //   description:
        //     "Co-Founder and President of the programming club called BluWings. Have organized several workshops which introduced programming and related tools to freshers.",
        //   color: "#D83B01",
        // },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects. Below are some of my projects. Note that not all of the mentioned projects are on GitHub yet.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "molindu.jpg",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I don't blog frequently but when I do something awesome, I do try my best to write a blog about it.",
    link: "https://medium.com/@molinduachintha24",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      name: "Text Sequence Prediction Shakespearean Sonnets",
      url:
        "https://github.com/sandaruwanWGMA/Text-Sequence-Prediction-Shakespearean-Sonnets",
      description:
        "In this phase of the project, the focus shifts to predicting the subsequent word in a text sequence. Leveraging the principles covered thus far, I am tasked with crafting a model tailored for this predictive task. The training dataset for this endeavor is sourced from the captivating sonnets of William Shakespeare.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      name: "Enigma",
      url: "https://github.com/sandaruwanWGMA/enigma24",
      description:
        "Application for applicants to register for a hackathon called Enigma conducted by maths society of the university",
      languages: [
        {
          name: "React.js",
          iconifyClass: "logos-react",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    {
      name: "Explore my GitHub for diverse Machine Learning projects",
      url: "https://github.com/sandaruwanWGMA/Machine-Learning-Projects",
      description:
        "Application for applicants to register for a hackathon called Enigma conducted by maths society of the university",
      languages: [
        {
          name: "React.js",
          iconifyClass: "logos-react",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
    },
    {
      name: "Sign Language MNIST Multi-Class Classification",
      url:
        "https://github.com/sandaruwanWGMA/Sign-Language-MNIST-Multi-Class-Classification",
      description:
        "In this Note book, I will work on a multi-class classification problem. I will be using the Sign Language MNIST dataset, which contains 28x28 images of hands depicting the 26 letters of the english alphabet.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
